#user-info-cont {
  width: 100%;
  height: auto;
  background: transparent;
  padding: 2rem;
  .intro-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #353535;
    padding-bottom: 8px;
    .user-info-heading {
      font-size: 24px;
      font-weight: 500;
      color: white;
      margin-bottom: 0;
    }

    .export-btn {
      align-self: flex-start;
      padding: 10px 0px;
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      background-color: #bb1ccc;
      border: none;
      color: #fff;
      cursor: pointer;
      border-radius: 4px;
      transition: background-color 0.3s;

      svg {
        font-size: 18px;
      }
      p {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 0;
      }

      &:hover {
        background-color: #e27bed;
      }
    }
  }

  .search-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    .search-box {
      width: 400px;
      position: relative;
      .icon {
        color: #fff;
        font-size: 14px;
      }
      .search-icon {
        position: absolute;
        top: 36%;
        left: 4%;
      }
      .close-icon {
        position: absolute;
        top: 38%;
        right: 3%;
        color: #e27bed;
        cursor: pointer;
      }
      .search-input {
        width: 100%;
        outline: none;
        border: 1px solid #bb1ccc;
        background-color: transparent;
        font-size: 16px;
        font-weight: normal;
        color: #e27bed;
        border-radius: 4px;
        height: 50px;
        padding-left: 48px;
        display: grid;
        align-items: center;
        align-content: center;

        &:focus {
          outline: none;
          border: 1px solid #bb1ccc;
        }

        &::placeholder {
          font-size: 16px;
          color: #fff;
          font-weight: normal;
        }
      }
    }

    .filter-section {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;
      .filter-text {
        font-size: 18px;
        color: #fff;
        margin-bottom: 0;
      }
      .filter-select {
        width: 180px;
        font-size: 16px;
        font-weight: 500;
        border-radius: 4px;
        background-color: transparent;
        color: #fff;
        border: 1px solid #bb1ccc;
        height: 50px;
        padding-left: 10px;

        &:focus {
          border-color: #bb1ccc;
          color: #e27bed;
          outline: none;
        }
        &::placeholder{
          color: white !important;
        }

        option {
          background-color: transparent;
          color: #e27bed;
          letter-spacing: 1.2px;
          font-weight: 500;
        }
      }
      .refresh-icon {
        color: #fff;
        background-color: #bb1ccc;
        font-size: 18px;
        font-weight: 500;
        cursor: pointer;
        &:hover {
          color: #e27bed;
        }
      }
    }
  }
  .resetBox{
    width: 60px;
    height: 50px;
    background-color: #bb1ccc;
    display: grid;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    border-radius: 10px;
  }

  .details-section {
    margin-bottom: 16px;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .user-length {
      font-size: 20px;
      color: #fff;
      margin-bottom: 0;

      span {
        font-size: 24px;
        font-weight: 500;
        color: #e27bed;
      }
    }
  }
  .table-container {
    height: 100%;
    overflow: auto;
    .tableData {
      width: 100%;
      border-collapse: collapse;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Adjust box shadow */
      td,
      th {
        padding: 18px 6px;
        color: #fff;
        font-size: 16px !important;
      }
      tr:nth-child(even) {
        background-color: #353535 !important;
      }
      
      /* Style for odd rows */
       tr:nth-child(odd) {
        background-color: #696767 !important; 
      }

      tr {
        th {
          background-color: #bb1ccc;
          text-align: left;

        }
        td {
          text-align: left;
          font-size: 18px;
          text-transform: capitalize;
          // color: #e27bed;

          // &:first-child {
          //   margin-top: 12px;
          // }

          // &:last-child {
          //   margin-bottom: 12px;
          // }
        }
      }
    }
    .Name-cont {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      padding-left: 10px;
      cursor: pointer;
      p{
        margin-bottom: 0;
      }
      .sort-cont {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
      }
    }
  }
  .table-container {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  .table-container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .refresh-btn {
    font-weight: 500;
    color: #e27bed;
    cursor: pointer;
  }
}

.pagination{
  .page-item{
    a{
      color: #bb1ccc;
    }
  }
  .active>.page-link{
    border: 1px solid #bb1ccc !important;
    color: white;
    outline: none;
  }
  & :focus{
    border-color:#bb1ccc ;
  }
}
 
