.mainBody {
  margin-top: 75px;
  position: relative;
  margin-left: 250px;
  border: 1px solid;
  margin-right: 10px;
}
.userinfo {
  font-size: 24px;
  font-weight: 600;
}
.WelcomeBack {
  font-size: 18px;
  font-weight: 400;
}
.settings {
  background-color: white;
  right: 0;
  height: 88vh;
  box-shadow: 0px 0px 10px;
  width: 500px;
  overflow: hidden;
  position: fixed;
  z-index: 1;
  overflow-y: auto;
  display: none;
  padding: 10px;
}
.headerTextFontcolorNew {
  font-size: 24px !important;
  text-align: left !important;
  font-weight: bold !important;
  padding-bottom: 2rem;
  display: block;
}
.submit_btn {
  width: 142px;
  height: 40px;
  border: none;
}
.iconsDiv {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #f1f3f8;
  display: grid;
  align-items: center;
  justify-items: center;
}
.card-box {
  display: grid;
  grid-gap: 40px;
  align-items: center;
  align-content: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 1.8rem;
  border-radius: 10px;
  text-align: left;
  min-height: 135px;
  max-height: 135px;
  grid-template-columns: 10% 75%;
  cursor: pointer;
  position: relative;
  min-width: 320px;
  max-width: 320px;
}
.card-box::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background: #0000a0;
  width: 8px;
  height: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.ContentDiv {
  display: grid;
  grid-gap: 5px;
}
.headerText {
  font-size: 22px;
  padding-bottom: 1rem;
  padding-top: 1rem;
  display: block;
}
.ContentDivText {
  font-weight: bold;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: initial;
  display: -webkit-inline-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
  text-align: left;
  color: #0000a0;
}
.last_update {
  font-size: 12px;
  color: #808080;
  line-height: 1.2em;
}
.headerTextReport {
  margin-top: 1rem;
  font-weight: 600;
  color: #0000a0;
}
.dept-items {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  grid-gap: 1rem;
}
.box_link_items {
  text-decoration: none !important;
  color: initial !important;
}
.box_link_items:hover {
  text-decoration: none !important;
  background-color: transparent !important;
}
.header_icons_btn {
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
}
.toogleIconsClass {
  color: #eee;
}

.navbar {
  --bs-navbar-hover-color: transparent !important;
}
#navbarScrollingDropdown:hover {
  background-color: transparent !important;
}

.dropdown-menu {
  background-color: white !important;
}
.logoutCss {
  bottom: 100px;
  cursor: pointer;
  position: absolute;
}

.tooltip_home {
  position: relative;
  display: inline-block;
}

.tooltip_home .tooltiptext_home {
  display: none;
  background-color: black !important;
  color: #fff !important;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  font-size: 16px;
  width: auto;
  min-width: 10px;
  max-width: 300px !important;
}

.tooltip_home:hover .tooltiptext_home {
  display: block;
}

.tooltip_home .tooltiptext_home::after {
  content: " ";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 12%;
  margin-left: -1px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

#main {
  /* border: 1px dotted black; */
  height: calc(100vh -100px);
  width: 100%;
  padding: 10px;
  /* overflow: hidden; */
}

#mainHome {
  /* border: 1px dotted black; */
  height: calc(100vh - 100px);
  width: 100%;
  /* padding: 10px; */
  /* overflow: hidden; */
  border: 2px solid #333;
  overflow-y: auto;
  /* overflow-x: hidden; */
}

#mainHome {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

#mainHome::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.navButtonToggle {
  position: fixed !important;
  z-index: 1101;
  border: 1px dotted rgba(86, 88, 105, 1) !important;
  backdrop-filter: blur(6px);
  left: 286px !important;
  padding: 0 !important;
  top: 92px !important;
  background-color: white !important;
}
.loadingCss {
  display: grid;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  height: 100%;
}

#DataTable td,
#DataTable th {
  padding: 1rem;
}
#DataTable td {
  font-size: 14px;
}

#DataTable {
  border-collapse: separate;
  border-spacing: 5px;
}
.item_CSL {
  background-color: rgb(243, 206, 206);
  padding: 5px;
  color: black;
  border-radius: 6px;
  font-size: 14px;
}
.supplyPlaning {
  background-color: rgb(203, 247, 162);
  padding: 5px;
  color: black;
  border-radius: 6px;
  font-size: 14px;
}
.SupplyPlanningCSL {
  background-color: rgb(141, 176, 230);
  padding: 5px;
  color: black;
  border-radius: 6px;
  font-size: 14px;
}
.DemandPlanning {
  background-color: rgb(248, 223, 168);
  padding: 5px;
  color: black;
  border-radius: 6px;
  font-size: 14px;
}

.DemandPlanningSupplyPlanning {
  background-color: rgb(248, 223, 168);
  padding: 5px;
  color: black;
  border-radius: 6px;
  font-size: 14px;
}
.naitems {
  background-color: rgb(201, 195, 195);
  padding: 5px;
  color: black;
  border-radius: 6px;
  font-size: 14px;
}

/* / */

section {
  overflow: hidden;
  position: relative;
  background-position: center center;
  background-size: cover;
}

.section-header {
  margin-bottom: 60px;
}

.section-title {
  color: #1c1c1c;
  margin-bottom: 60px;
  text-transform: uppercase;
}
.section-title span {
  position: relative;
}
.section-title span:before {
  content: "";
  position: absolute;
  bottom: -30px;
  right: 0;
  width: 100%;
  height: 1px;
  background-color: #1c1c1c;
}

.ft-wt-bold {
  font-weight: 700;
}

.section-subtitle {
  font-size: 17px;
  color: #666666;
}

.features-item {
  background-color: #f5f5f5;
  position: relative;
  transition: 0.3s;
  border-radius: 2px;
  display: grid;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  min-height: 270px;
}
.features-item::after {
  content: "";
  width: 100%;
  height: 5px;
  background-color: #bb1ccc;
  position: absolute;
  bottom: 0px;
}
.items-main {
  display: grid;
  gap: 1.2rem;
  grid-template-columns: repeat(4, 23.5%);
  justify-content: center;
}

.features-item:hover {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  cursor: pointer;
}
.features-item:hover .features-icon {
  color: #536dfe;
  border-color: #536dfe;
}
.features-item:hover .features-info .title {
  /* color: #536dfe;
    border-color: #536dfe; */
}
.features-item:hover .features-info p {
  color: #536dfe;
  border-color: #536dfe;
}

.features-icon {
  font-size: 36px;
  line-height: 96px;
  text-align: center;
  background: transparent;
  color: #bdbdbd;
  transition: 0.3s;
  display: grid;
  justify-content: start;
  align-items: center;
  justify-items: center;
  width: 100%;
  margin-bottom: 1rem;
  padding-left: 0.7rem;
}

@media only screen and (max-width: 992px) {
  .clear-problem > div:nth-child(3n + 1) {
    clear: none;
  }
}
@media only screen and (max-width: 768px) {
  .section-common-space {
    padding: 50px 0;
  }

  h4 {
    font-size: 17px;
  }

  h2 {
    font-size: 21px;
  }

  .section-title {
    margin-bottom: 20px;
  }
  .section-title span:before {
    bottom: -10px;
  }
}
.clear-problem > div:nth-child(3n + 1) {
  clear: left;
}

.features-info .title {
  color: #1c1c1c;
  font-weight: 700;
  font-size: 14px;
  padding: 10px;
  text-align: left;

  line-height: 1.7 !important;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-align: left !important;
  height: 30px;
  font-size: 14px;
}

.heading {
  font-size: 24px;
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #3d3d3d;
}

.line {
  width: 70px;
  height: 3px;
  background-color: #3d3d3d;
  display: block;
}
.line3 {
  width: 50px;
  height: 2.5px;
  background-color: #3d3d3d;
  display: block;
}

.heading1 {
  font-size: 24px;
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0rem;
}

.line1 {
  width: 70px;
  height: 4px;
  background-color: black;
  margin-left: 0rem;
  display: block;
}

.report-name {
  display: grid;
  align-items: flex-start;
  justify-content: start;
  text-align: left;
  font-size: 20px;
  font-weight: 500;
  color: #333;
  padding-left: 1rem;
  padding-top: 1rem;
}
.userinfo {
  font-size: 26px;
  font-weight: 500;
}

.search-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 20px;

  .search {
    width: 300px;
    height: 45px;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #bb1ccc;
    padding-top: 12px;
    font-size: 16px;
  }
}

.sidenavEmail {
  font-size: 14px;
}

.sidenavName {
  font-size: 16px;
}

.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 0 20px 16px;
  border-radius: 8px;
  box-shadow: 24px;
  border: none;
  width: 850px;
  max-height: 600px;
  overflow: auto;

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid black;
  }

  .modal-heading {
    font-size: 30px;
    font-weight: 600;
  }

  .modal-content {
    font-size: 18px;
    font-weight: 500;
    margin-top: 1.5rem;
  }

  .report-heading {
    font-size: 14px;
    font-weight: 700;
  }

  .heading-content {
    font-size: 12px;
    font-weight: 500;
  }

  .points {
    font-size: 12px;
    font-weight: 500;
    margin-left: 20px;
  }

  .main-container {
    .input_field {
      width: 250px;
      padding: 4px 8px;
      /* box-shadow: none; */
    }
  }
}

.nav-item {
  margin-right: 0px;
  border-radius: 8px;
  color: #0000a0;
  font-family: Arial, Helvetica, sans-serif;

  .nav-link {
    color: blue;
    font-weight: 500;
    letter-spacing: 0.15px;
    border: 1.5px solid blue;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
  }

  .nav-link.active {
    font-weight: 600;
  }
}

.tab-content {
  .active {
    background-color: transparent !important;
  }
}

.data_directory {
  &:hover {
    text-decoration: underline;
    color: blue;
  }
}

.data_directory_ReportOwnerBusiness {
  background-color: rgb(230, 227, 221);
  padding: 5px;
  color: black;
  border-radius: 6px;
  font-size: 14px;
}

.powerbicls {
  background-color: rgb(255, 224, 161);
  padding: 5px;
  color: black;
  border-radius: 6px;
  font-size: 14px;
}

.excelCls {
  background-color: rgb(178, 252, 168);
  padding: 5px;
  color: black;
  border-radius: 6px;
  font-size: 14px;
}
.Tableaucls {
  background-color: rgb(243, 209, 176);
  padding: 5px;
  color: black;
  border-radius: 6px;
  font-size: 14px;
}
.websitecls {
  background-color: rgb(203, 207, 247);
  padding: 5px;
  color: black;
  border-radius: 6px;
  font-size: 14px;
}

.modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  box-shadow: 24px;
  border: none;
  overflow: auto;
  padding: 12px;
}

.rg-evaluaion {
  .main-heading {
    font-size: 24px;
    font-weight: 700;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #3d3d3d;
  }

  .heading-container {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;

    .heading-box {
      width: 95%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #e9ecef;
      padding: 2rem 2rem;
      margin-bottom: 20px;

      .btn-primary {
        width: 100px;
        padding: 6px;
        font-weight: 600;
        border: none;
      }
    }

    .text {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.3px;
    }
  }
}

.DesCls {
  line-height: 1.7 !important;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: left !important;
  height: 80px;
  margin-bottom: 20px;
  font-size: 14px;
  min-height: 80px;
  max-height: 80px;
}
.item-type {
  background-color: #eee;
}
.List_items {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: baseline;
  align-content: baseline;
}
/* //#C3F8F9 */
.item-type-accounts-payable {
  background-color: #c3f8f9 !important;
  color: #03787c !important;
  box-sizing: border-box;
  padding: 4px 8px 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  border-radius: 16px;
  height: 20px;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
}
.item-type-finance {
  background-color: #fed5d1 !important;
  color: #a0410d !important;
  box-sizing: border-box;
  padding: 4px 8px 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  border-radius: 16px;
  height: 20px;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
}

.item-type-vendor-payments {
  background-color: #b8cdf5 !important;
  color: #1b4bcf !important;
  box-sizing: border-box;
  padding: 4px 8px 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  border-radius: 16px;
  height: 20px;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
}

.item-type-procurement {
  background-color: #fed5d1;
  color: #a0410d;
  box-sizing: border-box;
  padding: 4px 8px 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  border-radius: 16px;
  height: 20px;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
}
.item-type-machine-learning {
  background-color: #fed5d1;
  color: #a0410d;
  box-sizing: border-box;
  padding: 4px 8px 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  border-radius: 16px;
  height: 20px;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
}
.item-type-classification-algorithm {
  background-color: #fed5d1;
  color: #a0410d;
  box-sizing: border-box;
  padding: 4px 8px 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  border-radius: 16px;
  height: 20px;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
}
.item-type-regression {
  background-color: #fed5d1;
  color: #a0410d;
  box-sizing: border-box;
  padding: 4px 8px 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  border-radius: 16px;
  height: 20px;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
}

.item-type-controllership {
  background-color: #fed5d1;
  color: #a0410d;
  box-sizing: border-box;
  padding: 4px 8px 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  border-radius: 16px;
  height: 20px;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
}

.item-type-internal-audit {
  background-color: #fed5d1;
  color: #a0410d;
  box-sizing: border-box;
  padding: 4px 8px 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  border-radius: 16px;
  height: 20px;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
}
.item-type-finance-and {
  background-color: #fed5d1;
  color: #a0410d;
  box-sizing: border-box;
  padding: 4px 8px 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  border-radius: 16px;
  height: 20px;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
}
.item-type-anomaly-detection {
  background-color: #fed5d1;
  color: #a0410d;
  box-sizing: border-box;
  padding: 4px 8px 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  border-radius: 16px;
  height: 20px;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
}

.item-type-generative-ai {
  background-color: #fed5d1;
  color: #a0410d;
  box-sizing: border-box;
  padding: 4px 8px 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  border-radius: 16px;
  height: 20px;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
}
.item-type-record-to {
  background-color: #fed5d1;
  color: #a0410d;
  box-sizing: border-box;
  padding: 4px 8px 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  border-radius: 16px;
  height: 20px;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
}
.item-type-process-analytics {
  background-color: #fed5d1;
  color: #a0410d;
  box-sizing: border-box;
  padding: 4px 8px 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  border-radius: 16px;
  height: 20px;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
}

.item-type-forecasting {
  background-color: #fed5d1;
  color: #a0410d;
  box-sizing: border-box;
  padding: 4px 8px 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  border-radius: 16px;
  height: 20px;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
}

.item-type-treasury {
  background-color: #fed5d1;
  color: #a0410d;
  box-sizing: border-box;
  padding: 4px 8px 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  border-radius: 16px;
  height: 20px;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
}
.item-type-cash-flow {
  background-color: #fed5d1;
  color: #a0410d;
  box-sizing: border-box;
  padding: 4px 8px 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  border-radius: 16px;
  height: 20px;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
}
.item-type-sales {
  background-color: #c5bbfa !important;
  color: #280abd !important;
  box-sizing: border-box;
  padding: 4px 8px 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  border-radius: 16px;
  height: 20px;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
}
.item-type-compliance-reporting {
  background-color: #caaeac !important;
  color: #351503 !important;
  box-sizing: border-box;
  padding: 4px 8px 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  border-radius: 16px;
  height: 20px;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
}

.item-type-raw-material {
  background-color: #d0b4f5 !important;
  color: #290558 !important;
  box-sizing: border-box;
  padding: 4px 8px 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  border-radius: 16px;
  height: 20px;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
}
.item-type-inventory-management {
  background-color: #eff0b5 !important;
  color: #323306 !important;
  box-sizing: border-box;
  padding: 4px 8px 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  border-radius: 16px;
  height: 20px;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
}
.item-type-inventory-classification,
.item-type-treasury {
  background-color: #baf5d9 !important;
  color: #043b20 !important;
  box-sizing: border-box;
  padding: 4px 8px 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  border-radius: 16px;
  height: 20px;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
}
.powerBitag {
  background-color: #faf7d1 !important;
  color: #423e04 !important;
  box-sizing: border-box;
  padding: 4px 8px 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  border-radius: 4px;
  height: 20px;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
  display: flex;
  gap: 5px;
}
.ApplicationTag {
  background-color: #f7e4d3 !important;
  color: #914606 !important;
  box-sizing: border-box;
  padding: 4px 8px 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  border-radius: 4px;
  height: 20px;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
  display: flex;
  gap: 5px;
}
.item-type-procurement,
.item-type-prime-spend,
.item-type-cash-flow {
  background-color: #f7f19e !important;
  color: #a1970b !important;
  box-sizing: border-box;
  padding: 4px 8px 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  border-radius: 16px;
  height: 20px;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
}
.item-type-controllership,
.item-type-generative-ai,
.item-type-process-analytics {
  background-color: #ccc88d !important;
  color: #1b1a03 !important;
  box-sizing: border-box;
  padding: 4px 8px 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  border-radius: 16px;
  height: 20px;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
}
.item-type-regression,
.item-type-internal-audit,
.item-type-forecasting,
.item-type-record-to {
  background-color: #e0aefd !important;
  color: #51087a !important;
  box-sizing: border-box;
  padding: 4px 8px 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  border-radius: 16px;
  height: 20px;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
}
.item-type-machine-learning {
  background-color: #fabea2 !important;
  color: #b6430d !important;
  box-sizing: border-box;
  padding: 4px 8px 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  border-radius: 16px;
  height: 20px;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
}
.item-type-finance-and {
  background-color: #a2fabc !important;
  color: #0a411a !important;
  box-sizing: border-box;
  padding: 4px 8px 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  border-radius: 16px;
  height: 20px;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
}
.item-type {
  background-color: #c4c3c3;
  color: #3d3d3d;
  box-sizing: border-box;
  padding: 4px 8px 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  border-radius: 16px;
  height: 20px;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
}

.item-type-span {
  padding: 0 3px;
}
.TagCls {
  max-height: 120px;
}

.card_box_new_div {
  align-content: center;
  align-items: center;
  background: #f5f5f5 !important;
  border-radius: 6px !important;
  box-shadow: 0 2px 5px -1px rgb(50 50 93/2%), 0 1px 3px -1px rgb(0 0 0/5%) !important;
  color: #3d3d3d !important;
  display: flex !important;
  justify-content: space-between !important;
  overflow: hidden;
  position: relative;
  transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}
.annualItmes {
  align-items: center;
  background-color: #bb1ccc;
  border-radius: 4px;
  color: #fff;
  display: grid;
  font-size: 12px;
  padding: 6px;
}

.dashBoardTab {
  display: grid;
  gap: 20px;
  margin: 10px;
  grid-template-columns: repeat(4, 23%);
}

#detailsPanel .detailsSection {
  min-width: 400px !important;
  max-width: 400px !important;
  padding: 1rem;
  display: grid;
  gap: 15px;
}
.headerDetails {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 10px;
  display: flex;
  align-content: center;
  align-items: center;
  gap: 5px;
}
.details-list {
  display: grid;
  justify-content: flex-start;
}

.item-completed {
  background-color: #aff8bf;
  color: #0da00d;
  padding: 4px 8px 5px 8px;
  overflow: hidden;
  display: flex;
  border-radius: 16px;
  height: 30px;
  align-items: center;
  font-size: 12px;
  justify-content: center;
}
.tab-content > .active {
  display: grid !important;
  gap: 20px !important;
}

.RlTypesCls {
  padding: 10px !important;
  display: block;
}
.namecls {
  font-size: 18px !important;
}
